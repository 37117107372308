.career-container {
  margin-top: 61px;
  background-color: #284a58;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg %3E%3Ccircle fill='%23284A58' cx='400' cy='400' r='600'/%3E%3Ccircle fill='%23294c5a' cx='400' cy='400' r='500'/%3E%3Ccircle fill='%232a4e5c' cx='400' cy='400' r='400'/%3E%3Ccircle fill='%232a4f5f' cx='400' cy='400' r='300'/%3E%3Ccircle fill='%232b5161' cx='400' cy='400' r='200'/%3E%3Ccircle fill='%232C5363' cx='400' cy='400' r='100'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;

}

.career-main-container {
  width: 90%;
  margin: auto;
}

.open-positions-text{
  padding: 48px 0;
}

.open-pos {
  padding: 50px 0 50px 0;
}

.open-pos p{
  padding-top: 16px;
}

.career-container h2 {
  font-size: 35px;
  font-weight: 700;
}

.career-container p {
  font-size: 20px;
}

.career-container .search input {
  width: 350px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.252);
  border-radius: 2px;
  border: none;
  top: 83%;
}

.career-container .search input:focus {
  width: 400px;
  background-color: rgba(255, 255, 255, 0.252);
  transition: 1s;
  color: white;
}

.career-container .career-dropdown {
  top: 0;
}

.job-container h5 {
  font-size: 20px;
  font-weight: 700;
}

.job-container .job-card {
  background-color: #f1fafe;
  box-shadow: 0px 3px 6px #00000029 !important;
}

.job-container .jobs .p {
  font-weight: 700;
}

.open-positions-text p {
  max-width: 75%;
  margin: auto;
  padding: 20px 0;
  line-height: 2;
  color: black;
  font-size: 17px;
}

.open-positions-text a {
  color: blue !important;
}

@media screen and (max-width: 992px) {
  .career-container {
    margin-top: 55px;
  }
}

@media screen and (max-width: 491px) {

  .open-pos{
    padding: 30px 0;
  }

  .open-positions-text{
    padding: 20px 0 !important;
  }
  .open-pos h2 {
    text-align: center;
    font-size: 30px;
  }

 .open-pos p{
  padding: 0;
 }

  .open-positions-text p {
    max-width: 90%;
    font-size: 16px;
    line-height: 1.7;
  }
  .open-positions-text p:nth-child(1) {
    text-align: justify;
  }

  .open-positions-text p:nth-child(2) {
    text-align: justify;
  }
}
