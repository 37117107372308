.privacy-container {
  margin-top: 65px;
  font-family: "Open Sans", sans-serif;
}

.privacy {
  width: 75% !important;
  margin: auto !important;
}

.privacy-container h1 {
  font-size: 36px;
  color: #333;
  padding: 80px 0 35px 0 !important;
}

.privacy-container p,
li {
  color: #666;
  font-size: 16px;
  text-align: justify;
  line-height: 30px;
}

.privacy-container b {
  line-height: 3;
}

@media screen and (max-width: 491px) {
  .privacy-container {
    margin-top: 55px !important;
  }

  .privacy {
    width: 90% !important;
  }
  

  .privacy-container h1 {
    padding: 40px 0 40px 0 !important;
    margin: 0;
  }

  .privacy-container p,
li {
  line-height: 1.9 !important;
}
}
