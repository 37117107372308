.roesection-content2 h2{
    font-size: 32px;
    font-weight: 600;
    color: #fff;
}
.roesection-content h2{
    font-size: 24px;
    font-weight: 400;
    color: #fff;
}

.roesection-content span{
    color: #13D09B;
}

.roesection-content p{
    font-size: 16px;
    color: #fff;
    padding: 20px 0;
}
.roesection-content p{
    font-size: 16px;
    color: #fff;
    font-weight: 400;
}

.roe-col2-content{
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 19px;
}
.roe-column2{
    margin-left: 120px;
}
.roe-col2-content h2{
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    margin-bottom: 0px;
}

.roe-col2-content p{
    font-size: 14px;
    color: #fff;
    margin-bottom: 0px;
}


@media screen and (max-width: 491px) {
    .roe-column2{
        margin-left: 0px;
    }
}

@media screen and (max-width: 991px) {
    .roesection-content {
        padding: 20px 0;
    }
    .roe-column2{
        margin-left: 0px;
    }
    .roe-col2-content{
        display: flex;
        align-items: center;
        gap: 40px;
        padding: 10px;
    }
    .roesection-content2 h2{
        font-size: 28px;
        font-weight: 600;
        color: #fff;
    }
}