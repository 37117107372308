* {
  box-sizing: border-box;
}

body {
  margin: 0 !important;
  padding: 0 !important;
}

.p-60 {
  padding: 0 150px !important;
}

@media screen and (max-width : 600px) {
  .p-60 {
    padding: 0 10px !important;
  }
}

@media screen and (max-width : 1400px) {
  .p-60 {
    padding: 10px !important;
  }
}