.businessedgesolution{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.businessedge_img{
    width: 60px;
}

@media screen and (min-width: 492px) and (max-width: 768px) {
    .businessedge_content{
        width: 50%;
    }
}
@media screen and (max-width: 491px) {
    .businessedge_content{
        width: 50%;
    }
}