.blog7-container {
    margin-bottom: 5rem;
  }
  
  .blog7-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    background-color: #2c5363;
    height: 312px;
  }
  
  .blog7-header h1 {
    color: #fff;
    font-size: 2.5rem;
    text-align: center;
    width: 70%;
  }
  
  .blog7-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px 300px;
  }
  
  .blog7-image {
    border-radius: 12px;
  }
  
  .blog7-text {
    margin-top: 4rem;
  }
  
  .blog7-section-title {
    font-size: 25px;
    color: #2c5363;
    font-weight: 600;
    padding-bottom: 10px;
    line-height: 1;
  }
  
  .blog7-paragraph {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #21252;
    padding-bottom: 10px;
  }
  
  /* Media Queries */
  
  /* Minimum width: Tablet (768px and above) */
  @media (min-width: 768px) {
    .blog7-header h1 {
      font-size: 3rem;
    }
  
    .blog7-content {
      padding: 10px 200px;
    }
  
    .blog7-section-title {
      font-size: 28px;
    }
  }
  
  /* Maximum width: Tablet (768px and below) */
  @media (max-width: 768px) {
    .blog7-header {
      height: 260px;
    }
  
    .blog7-header h1 {
      font-size: 2rem;
      width: 80%;
    }
  
    .blog7-content {
      padding: 10px 50px;
    }
  
    .blog7-section-title {
      font-size: 22px;
    }
  
    .blog7-paragraph {
      font-size: 0.9rem;
    }
  }
  
  /* Maximum width: Mobile (480px and below) */
  @media (max-width: 480px) {
    .blog7-header {
      height: 200px;
      margin-top: 50px;
    }
  
    .blog7-header h1 {
      font-size: 16px;
      width: 90%;
    }
  
    .blog7-content {
      padding: 10px 20px;
    }
  
    .blog7-section-title {
      font-size: 20px;
    }
  
    .blog7-paragraph {
      font-size: 0.85rem;
    }
  }
  