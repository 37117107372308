.read-more-container {
  margin-top: 61px;
  background-color: #2c5363 !important;
}

.read-more-main-container {
  width: 100%;
  margin: auto;
  padding: 0px 400px;
  
}

.read-more-container .header {
  padding: 80px 0 !important;
}

.read-more-main-container .blog-intro{
  padding: 10px 10px;
}

.read-more-main-container .blog-intro .intro p {
  text-align: justify;
}

.read-more-main-container .blog-intro .aeps-table{
  width: 30%;
  margin: 30px !important;
}

.read-more-main-container .blog-intro h2 {
  color: #2c5363;
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 10px !important;
}

.read-more-main-container .blog-intro b {
  color: #2c5363;
}

.read-more-main-container .blog-intro li {
  text-align: justify;
  color: black;
  list-style-type: square;
}

.read-more-main-container .blog-intro .blog-ul p {
  color: black;
}

.read-more-main-container .blog-intro .blog-ul li {
  list-style-type: disc !important;
}

.read-more-main-container .blog-intro .internal-blog-btn .btn {
  background-color: #16b287;
  font-weight: 600;
}

.read-more-main-container .blog-intro .comment-btn .btn {
  background-color: #2c5363;
  font-size: 14px;
}

.read-more-main-container .blog-sidebar .articles p {
  margin-left: 15px;
}

@media screen and (max-width: 1400px) {
  .read-more-main-container .blog-intro .aeps-table{
    width: 50%;
    margin: 30px !important;
  }  
}

@media screen and (max-width: 992px) {
  .read-more-container {
    margin-top: 55px;
  }
 
}

@media screen and (max-width :768px) {
  .read-more-main-container .blog-intro .aeps-table{
    width: 60%;
    margin: 30px !important;
  }  
}

@media screen and (max-width : 600px) {
  .read-more-main-container .blog-intro .aeps-table{
    width: 70%;
    margin: 30px !important;
  } 
}

@media screen and (max-width: 491px) {

  .read-more-container .header {
    padding: 50px 0;
  }

  .read-more-container .header h1 {
    font-size: 24px;
  }
  .read-more-main-container {
    width: 100%;
    margin: auto;
    padding: 0px 20px;

  }
  

  .read-more-main-container .row {
    margin-top: 0 !important;
  }

  .read-more-main-container .blog-intro h2 {
    font-size: 22px;
    margin-top: 30px;
  }

  .read-more-main-container p,
  li {
    font-size: 14px !important;
  }

  .read-more-main-container li {
    line-height: 1.5;
  }

  .read-more-main-container .blog-intro .aeps-table{
    width: 80%;
    margin: 30px !important;
  }
}
