.become-container {
  margin-top: 61px;
  height: 50vh;
  background-size: 100%;
  background-color: #2c5363;
}

.become-main-container {
  width: 80%;
  margin: auto;
  height: 40vh;
}

.become-content {
  padding: 60px 0;
}

.become-content h1 {
  margin-top: 50px;
  font-size: 32px;
  font-weight: 700;
}

.become-content span {
  color: #16b287 !important;
  line-height: 2.5 !important;
}

.become-content .become-content-list img {
  margin-right: 10px !important;
}

.become-content .become-middle-img {
  margin-right: 60px;
}

.become-main-container .become-form {
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0px 3px 6px #00000029 !important;
}

.become-main-container .become-form .btn {
  background-color: #16b287;
  border: none;
  font-weight: 700;
  padding: 10px 0;
}

.become-main-container .become-form input {
  font-size: 12px;
  padding: 10px 10px;
}

.become-main-container .become-form textarea {
  font-size: 12px;
  padding: 10px 10px;
}

.become-main-container .become-form .checkboxes label {
  font-size: 11px;
  margin-left: 8px;
}

.become-main-container .become-form .check-head {
  font-weight: 600;
  font-size: 13px;
}

.become-main-container .become-form .form-policy p {
  font-size: 12px;
}

.become-main-container .become-form .form-policy p a {
  text-decoration: none;
  color: #16b287;
}

.partner-bottom {
  margin-top: 100px;
}

.large-devices-become-heading {
  display: block !important;
}

.small-devices-become-heading {
  display: none !important;
}

.formcheck p{
  font-size: 15px;
  margin-top: 13px;
}


@media screen and (max-width: 992px) {
  .become-container {
    height: 80vh;
    margin-top: 55px;
    background-size: 50% !important;
  }

  .partner-bottom {
    display: none !important;
  }

  .small-device-partner-left {
    display: flex !important;
    justify-content: center !important;
  }
  .formcheck p{
    font-size: 15px;
    margin-top: 13px;
  }
}

@media screen and (max-width: 768px) {
  .become-container {
    height: 120vh;
    background-color: #2c5363;
    padding-bottom: 50px !important;
  }

  .become-content {
    padding: 30px 0 !important;
  }

  .large-devices-become-heading {
    margin: 20px 0 40px 0 !important;
  }
  .formcheck p{
    font-size: 15px;
    margin-top: 13px;
  }
}

@media screen and (max-width: 491px) {
  .become-container {
    height: 120vh;
    background-color: #2c5363;
    padding-bottom: 50px !important;
  }

  .become-content {
    padding: 30px 0;
  }

  .large-devices-become-heading {
    display: none !important;
  }

  .small-devices-become-heading {
    display: block !important;
  }

  .become-content .small-devices-become-heading .become-color-span {
    color: #16b287 !important;
  }

  .small-devices-become-heading .become-h1-span {
    color: white !important;
    line-height: 1.5 !important;
    font-size: 25px !important;
  }

  .become-content h1 {
    margin: 0 !important;
    font-size: 25px;
    text-align: center;
  }

  .become-form {
    max-height: 80vh !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }

  .small-device-channel-hide {
    display: none !important;
  }
  .become-form .checkboxes {
    padding: 0 !important;
    margin: 0 !important;
  }

  .become-form .checkboxes input {
    height: 5px !important;
    width: 5px !important;
    padding: 10px !important;
  }
  .become-form .checkboxes label {
    font-size: 8px !important;
  }
  .formcheck p{
    font-size: 15px;
    margin-top: 13px !important;
   }
}
